import { useIsFocused } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { DataTable, Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { Screens } from "../../../common/Constant";
import { encryptedData } from "../../../common/Utils";
import BgImageContainer from "../../../components/BgImageContainer";
import AddAdminModal from "../../../components/Organization/AdminScreen/AddAdminModal";
import AdminBox from "../../../components/Organization/AdminScreen/AdminBox";
import AdminHeader from "../../../components/Organization/AdminScreen/AdminHeader";
import { ProgressBar } from "../../../components/progressBar/ProgressBar";
import { useRootContext } from "../../../context/rootContext/RootContext";
import { navigate } from "../../../navigation/RouteNavigation";
import { OrganizationUser } from "../types";
import { adminScreenStyles } from "./AdminScreen.Styles";
import AdminScreenController from "./controller/AdminScreenController";
import AdminBoxItem from "../../../components/Organization/AdminScreen/AdminBoxItem";
import Table from "../../../components/GenericTable";
import Actions from "../../../components/Organization/AdminScreen/Actions";
import TableCell from "../../../components/GenericTable/TableCell";
import { TableRow } from "../../../components/GenericTable/TableRow";
type props = {};
const TableRowName = [Strings.NAME, Strings.EMAIL, Strings.ACTION];
const AdminsScreen: React.FC<props> = () => {
  const [loading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [usersList, setUsersList] = useState<OrganizationUser[]>([]);
  const [addAdminModalVisible, setAddAdminModalVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const {
    dimensions: { width },
  } = useRootContext();
  const { getAllAdminsList, sendInvitationToAdmin } = AdminScreenController();

  useEffect(() => {
    let ignore = false;

    setIsLoading(true);

    getAllAdminsList().then((initialData) => {
      if (!ignore) {
        if (!initialData.hasError) {
          setUsersList(initialData.data);
          setErrorMessage("");
        } else {
          setErrorMessage(initialData.message);
        }
      }
    })
    .finally(() => setIsLoading(false));
    
    return () => {
      ignore = true;
    }
  }, []);

  const sendInvitation = async (email: string) => {
    setModalMsg(Strings.LOADING_STARTED);
    await sendInvitationToAdmin(email).then((data) => {
      if (!data.hasError) {
        setModalMsg(Strings.INVITATION_SENT);
      } else {
        setModalMsg(data.message);
      }
    });
  };

  return (
    <ProgressBar loading={loading}>
      <BgImageContainer isOrganization={true}>
        {!loading && (
          <View
            style={[
              adminScreenStyles.scrollContainer,
              { width: width > 600 ? 600 : "98%" },
            ]}
          >
            <AdminHeader
              onAddUserClicked={() => setAddAdminModalVisible(true)}
            />
            <Table
              headerNames={TableRowName}
              alignFirstColumn={true}
              data={usersList}
              tableCell={(user) => (
                <TableRow key={user.id}>
                  <TableCell textStyles={{ textAlign: "left" }}>
                    {`${user.first_name} ${user.last_name}`}
                  </TableCell>
                  <TableCell>
                    {user.email}
                  </TableCell>
                  <Actions
                    style={{ flex: 1, textAlign: "center" }}
                    onEdit={() => {
                      navigate(Screens.EDIT_ADMIN_SCREEN, {
                        data: encryptedData(user),
                      })
                    }}
                  />
                </TableRow>
              )}
            />
            <AddAdminModal
              visible={addAdminModalVisible}
              onDismiss={() => {
                setAddAdminModalVisible(false);
                setModalMsg("");
              }}
              isLoading={modalMsg === Strings.LOADING_STARTED}
              onSendInvitation={sendInvitation}
              message={modalMsg}
            />
            {errorMessage ? (
              <Text style={adminScreenStyles.errorTxt}>{errorMessage}</Text>
            ) : null}
          </View>
        )}
      </BgImageContainer>
    </ProgressBar>
  );
};

export default AdminsScreen;
