import { useIsFocused } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { Strings } from "../../../../assets/strings";
import { Screens } from "../../../common/Constant";
import BgImageContainer from "../../../components/BgImageContainer";
import CustomButton from "../../../components/Buttons/CustomButton";
import ClickableText from "../../../components/ClickableText";
import ErrorText from "../../../components/ErrorText";
import FormInput from "../../../components/FormInput";
import ChangePasswordModal from "../../../components/Organization/ProfileEditScreen/ChangePasswordModal";
import ChangeEmailModal from "../../../components/Profile/Modals/ChangeEmailModal";
import { ProgressBar } from "../../../components/progressBar/ProgressBar";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext";
import validationSchema from "../../../data/validationSchema";
import { navigate } from "../../../navigation/RouteNavigation";
import { theme } from "../../../theme";
import { Profile } from "../types";
import ProfileEditScreenController from "./controller/ProfileEditScreenController";
import { profileEditScreenStyles } from "./ProfileEditScreen.Styles";

type props = {};

const ProfileEditScreen: React.FC<props> = () => {
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState<Profile>(null);
  const [loading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMsg] = useState("");
  const isFocused = useIsFocused();
  const {
    raiseEmailChangeRequest,
    verifyEmailChangePasscode,
    updateProfile,
    getUserProfile,
    changePasscode,
  } = ProfileEditScreenController();
  const { orgAuthState } = useOrgAuthContext();

  const getProfile = async () => {
    try {
      setIsLoading(true);

      const initialUser = await getUserProfile(orgAuthState.orgUserToken);
  
      if (!initialUser.hasError) {
        setProfile(initialUser.data);
        setMessage("");
      } else {
        setMessage(initialUser.message);
      }

    } finally {
      setIsLoading(false);
    }
  };

  const updateUserProfile = async (firstName: string, lastName: string) => {
    setMessage(Strings.LOADING_STARTED);
    await updateProfile(orgAuthState.orgUserToken, firstName, lastName).then(
      (res) => {
        if (!res.hasError) {
          setMessage("");
          getProfile();
        } else {
          setMessage(res.message);
        }
      }
    );
  };

  const changePassword = async (currentPass: string, newPass: string) => {
    setModalMsg(Strings.LOADING_STARTED);
    await changePasscode(orgAuthState.orgUserToken, currentPass, newPass).then(
      (res) => {
        if (!res.hasError) {
          setModalMsg(Strings.LOADING_SUCCESS);
        } else {
          setModalMsg(res.message);
        }
      }
    );
  };

  useEffect(() => {
    getProfile();
  }, []);

  const sendOtpForEmailEdit = async (email: string) => {
    const response = await raiseEmailChangeRequest(
      orgAuthState.orgUserToken,
      email
    );
    return response;
  };

  const verifyEmailChange = async (email: string, passcode: string) => {
    const response = await verifyEmailChangePasscode(
      orgAuthState.orgUserToken,
      email,
      passcode
    );
    return response;
  };

  return (
    <ProgressBar loading={loading}>
      <BgImageContainer isOrganization={true}>
        {!loading && (
          <View style={profileEditScreenStyles.container}>
            <Text style={profileEditScreenStyles.heading}>
              {Strings.EDIT_PROFILE}
            </Text>
            <Formik
              initialValues={{
                firstName: profile ? profile.first_name : "",
                lastName: profile ? profile.last_name : "",
                email: profile ? profile.email : "",
              }}
              validationSchema={validationSchema.updateOwner}
              onSubmit={(values) => {
                updateUserProfile(values.firstName, values.lastName);
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => (
                <View style={{ width: "100%", marginTop: 30 }}>
                  <View style={[profileEditScreenStyles.inputsContainer]}>
                    <View style={profileEditScreenStyles.inputContainer}>
                      <FormInput
                        label={Strings.FIRST_NAME}
                        inputPlaceholder={Strings.FIRST_NAME}
                        inputValue={values.firstName}
                        setInputValue={handleChange("firstName")}
                        mode="outlined"
                        inputWidth="100%"
                        error={touched.firstName && errors.firstName}
                        labelStyle={profileEditScreenStyles.label}
                        style={profileEditScreenStyles.input}
                        activeOutlineColor="#9797FF"
                        outlineColor={theme.colors.btn}
                        dense
                      />
                      {(touched.firstName && errors.firstName) ===
                        undefined && (
                        <View
                          style={profileEditScreenStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                    <View style={profileEditScreenStyles.inputContainer}>
                      <FormInput
                        label={Strings.LAST_NAME}
                        inputPlaceholder={Strings.LAST_NAME}
                        inputValue={values.lastName}
                        setInputValue={handleChange("lastName")}
                        mode="outlined"
                        inputWidth="100%"
                        error={touched.lastName && errors.lastName}
                        labelStyle={profileEditScreenStyles.label}
                        style={profileEditScreenStyles.input}
                        activeOutlineColor="#9797FF"
                        outlineColor={theme.colors.btn}
                        dense
                      />
                      {(touched.lastName && errors.lastName) === undefined && (
                        <View
                          style={profileEditScreenStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                  </View>
                  <View style={[profileEditScreenStyles.inputsContainer]}>
                    <View style={profileEditScreenStyles.inputContainer}>
                      <FormInput
                        label={Strings.EMAIL}
                        inputPlaceholder={Strings.EMAIL}
                        inputValue={values.email}
                        setInputValue={handleChange("email")}
                        mode="outlined"
                        inputWidth="100%"
                        error={touched.email && errors.email}
                        labelStyle={profileEditScreenStyles.label}
                        style={profileEditScreenStyles.input}
                        activeOutlineColor="#9797FF"
                        outlineColor={theme.colors.btn}
                        editable={false}
                        updateText="Update email"
                        onUpdatePress={() => setEmail(profile.email)}
                        dense
                      />
                      {(touched.email && errors.email) === undefined && (
                        <View
                          style={profileEditScreenStyles.errorContainer}
                        ></View>
                      )}
                    </View>
                  </View>
                  <CustomButton
                    style={{ marginTop: 20 }}
                    onPress={handleSubmit}
                    name={Strings.UPDATE}
                    isLoading={message === Strings.LOADING_STARTED}
                  />
                  <ClickableText
                    containerStyle={{ marginTop: 10 }}
                    text="Change Password"
                    style={{ fontSize: 15 }}
                    onPress={() => setModalVisible(true)}
                  />
                </View>
              )}
            </Formik>
            {message && message !== Strings.LOADING_STARTED ? (
              <ErrorText txtStyle={{ marginVertical: 10 }} error={message} />
            ) : null}
            <ChangeEmailModal
              visible={email ? true : false}
              onDismiss={() => setEmail("")}
              email={email}
              onSendOtpClicked={sendOtpForEmailEdit}
              onVerifyPasscode={verifyEmailChange}
            />
            <ChangePasswordModal
              visible={modalVisible}
              onDismiss={() => setModalVisible(false)}
              style={{ height: 350, width: 450 }}
              error={modalMessage}
              onChange={changePassword}
            />
          </View>
        )}
      </BgImageContainer>
    </ProgressBar>
  );
};

export default React.memo(ProfileEditScreen);
