import { useIsFocused } from "@react-navigation/native";
import React, { useEffect, useMemo, useState } from "react";
import { ScrollView } from "react-native";
import { Strings } from "../../../../assets/strings";
import { Screens } from "../../../common/Constant";
import { encryptedData } from "../../../common/Utils";
import BgImageContainer from "../../../components/BgImageContainer";
import ErrorText from "../../../components/ErrorText";
import DeleteModal from "../../../components/Modal/DeleteModal";
import FilterModal from "../../../components/Modal/FilterModal";
import WarningsModal from "../../../components/Modal/WarningsModal";
import Header from "../../../components/Organization/ClientScreen/Header";
import UploadClientDocModal from "../../../components/Organization/ClientScreen/UploadClientDocModal";
import { ProgressBar } from "../../../components/progressBar/ProgressBar";
import { useOrgAuthContext } from "../../../context/OrganizationAuthContext";
import { useRootContext } from "../../../context/rootContext/RootContext";
import { navigate } from "../../../navigation/RouteNavigation";
import { Client, DataActionType } from "../types";
import { clientScreenStyles } from "./ClientScreen.Styles";
import { Text } from "react-native-paper"
import ClientScreenController from "./controller/ClientScreenController";
import Table from "../../../components/GenericTable";
import TableCell from "../../../components/GenericTable/TableCell";
import { TableRow } from "../../../components/GenericTable/TableRow";
import ClientStatusText from "../../../components/Organization/ClientScreen/ClientStatusText";
import Actions from "../../../components/Organization/ClientScreen/Actions";
import { clientBoxItemStyles } from "../../../components/Organization/ClientScreen/ClientBoxItem/ClientBoxItem.Styles";
import ClientNameText from "../../../components/Organization/ClientScreen/ClientNameText";

type props = {};
const TableColumnNames = [
  Strings.NAME,
  Strings.ConfirmInfo.EMPLOYER,
  Strings.DAYS_SPENT,
  Strings.DAYS_REMAINING,
  Strings.VISA_TYPE_NAME,
  Strings.STATUS,
  Strings.ACTION
];
const ClientScreen: React.FC<props> = () => {
  const [isLoading, setLoading] = useState(true);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [warningsModalVisible, setWarningsModalVisible] = useState(false);
  const [client, setClient] = useState<Client>();
  const [clientList, setClientList] = useState([]);
  const [filteredClientList, setFilteredClientsList] = useState([]);
  const [clientListMsg, setClientListMsg] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [modalMsg, setModalMsg] = useState("");
  const [filter, setFilter] = useState([]);
  const isFocused = useIsFocused();
  const [duration, setDuration] = useState(0);
  const { handleCBP } = ClientScreenController();
  const loading = clientListMsg === Strings.LOADING_STARTED;
  
  const { orgAuthState } = useOrgAuthContext();
  const {
    dimensions: { width },
  } = useRootContext();

  const { getAllClientsOfOrganization, removeClient, exportToCsv } =
    ClientScreenController();

  const getAllClients = async () => {
    try {
      setLoading(true);
      setClientListMsg(Strings.LOADING_STARTED);
  
      const initialClients = await getAllClientsOfOrganization(
        orgAuthState.orgId,
        orgAuthState.orgUserToken
      );
  
      if (!initialClients.hasError) {
  
        const clientList = initialClients.data.map(item => {
          if (!item.non_immigrant_status) {
            item.non_immigrant_status = { status: "PROCESSING" }
          }

          return item
        });
        
        setClientList(clientList);
        setClientListMsg("");
      } else {
        setClientListMsg(initialClients.data);
      }
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
      getAllClients();
  }, []);

  const setRefreshStatusError = (msg: string) => {
    setClientListMsg(msg);
    setTimeout(() => {
      setClientListMsg("");
    }, 5000);
  };

  const refreshStatusCallback = (msg?: string) => {
    msg ? setRefreshStatusError(msg) : getAllClients();
  };

  const setStatusListOfClients = () => {
    setStatusList([]);
    const clientStatusList = new Set();
    clientList.map((client) => {
      client.non_immigrant_status &&
        client.non_immigrant_status.status &&
        clientStatusList.add(client.non_immigrant_status.status);
    });
    setStatusList([...clientStatusList]);
  };

  const filterClientByStatus = (client: Client) => {
    let isValid: boolean;
    filter?.length
      ? filter.map((status) =>
        client?.non_immigrant_status?.status === status
          ? (isValid = true)
          : {}
      )
      : (isValid = true);

    return isValid;
  };

  useEffect(() => {
    const filteredList = clientList.filter((client) =>
      filterClientByStatus(client)
    );
    setFilteredClientsList(filteredList);
  }, [filter, clientList]);

  useEffect(() => {
    setStatusListOfClients();
  }, [clientList]);


  const setClientModalData = (
    client: Client | null,
    type: DataActionType,
    visible: boolean
  ) => {
    if (type === DataActionType.Add) {
      setAddModalVisible(visible);
      setClient(client);
    } else if (type === DataActionType.Delete) {
      setClient(client);
      setDeleteModalVisible(visible);
    } else if (type === DataActionType.Warning) {
      setClient(client);
      setWarningsModalVisible(visible);
    }
  };

  const hideAddClientModal = (refresh: boolean) => {
    setClientModalData(null, DataActionType.Add, false);
    getAllClients();
  };

  const handleOnDeleteClicked = () => {
    setModalMsg(Strings.LOADING_STARTED);
    removeClient(
      orgAuthState.orgId,
      orgAuthState.orgUserToken,
      client.uid
    ).then((res) => {
      if (!res.hasError) {
        getAllClients();
        setModalMsg("");
      } else {
        setModalMsg(res.data);
      }
      setClientModalData(null, DataActionType.Delete, false);
    });
  };

  const handleOnUpdateClicked = (client: Client) => {
    if (client.profile_completed)
      navigate(Screens.EDIT_ORG_CLIENT_SCREEN, { data: encryptedData(client) });
    else {
      setClientModalData(client, DataActionType.Add, true);
    }
  };
  
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const sortedClientsList = (clientsList: Client[]) => {
    return clientsList.sort((a, b) => {
      const aName = `${a.first_name} ${a.last_name}`;
      const bName = `${b.first_name} ${b.last_name}`;
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    });
  };

  const getDaysSpentAndRemaining = (client: Client) => {
    let daysRemaining : (string | number);
    let daysSpent : (string | number);
    let isNotValidVisaType = !Strings.VISATYPE.includes(client.non_immigrant_status?.visa_type);
  
    if (client.non_immigrant_status?.has_i140 || 
        isNotValidVisaType || 
        client.non_immigrant_status?.is_intermittent
      ) {
  
      if (client.non_immigrant_status?.has_i140) {
        daysRemaining = Strings.I140;
        daysSpent = client.non_immigrant_status?.total_cap_out_days_in_country;
      }
  
      if (client.non_immigrant_status?.is_intermittent) {
        daysRemaining = Strings.INTERMITTENT;
        daysSpent = Strings.INTERMITTENT;
      }
  
      if (isNotValidVisaType) {
        daysRemaining = Strings.N_A;
        daysSpent = Strings.N_A;
          
      }
    } else {
      daysRemaining = client.non_immigrant_status?.cap_out_days_remaining;
      daysSpent = client.non_immigrant_status?.total_cap_out_days_in_country;
    }
  
    return {
      daysRemaining,
      daysSpent,
    }
  };
  
  const onStatusRefreshClicked = async (client: Client) => {
    setDuration(10000);
    await handleCBP(client.uid, client.organization_id).then((res) => {
      setDuration(0);
      refreshStatusCallback(res.message);
    });
  };

  return (
    <ProgressBar loading={isLoading}>
      <BgImageContainer isOrganization={true}>
        {!isLoading && (
          <ScrollView
            contentContainerStyle={[
              clientScreenStyles.scrollContainer,
              { width: width >= 1090 ? 1090 : "95%" },
            ]}
          >
            <Header
              onSearchClicked={() => setFilterModalVisible(true)}
              onAddUserClicked={() => setAddModalVisible(true)}
              onCSVExport={() => {
                const data = exportToCsv(clientList);
                downloadFile({
                  data,
                  fileName: "client.csv",
                  fileType: "text/csv",
                });
              }}
              isBtnEnabled={clientList.length ? true : false}
            />
            <Table
              headerNames={TableColumnNames}
              alignFirstColumn={true}
              data={
                filter
                && filter.length
                  ? sortedClientsList(filteredClientList)
                  : sortedClientsList(clientList)
              }
              tableCell={(client) => (
                <TableRow key={client.email}>
                  <ClientNameText client={client} />
                  <TableCell>
                    {client.employer_name ? client.employer_name : Strings.N_A}
                  </TableCell>
                  <TableCell>
                    {client.non_immigrant_status
                      ? getDaysSpentAndRemaining(client).daysSpent
                      : Strings.N_A
                    }
                  </TableCell>
                  <TableCell>
                    {client.non_immigrant_status
                      ? getDaysSpentAndRemaining(client).daysRemaining
                      : Strings.N_A
                    }
                  </TableCell>
                  <TableCell>
                    {client.non_immigrant_status?.visa_type
                      ? client.non_immigrant_status.visa_type
                      : Strings.N_A
                    }
                  </TableCell>
                  <ClientStatusText
                    profileCompleted={client.profile_completed}
                    nonImmigrantStatus={client.non_immigrant_status}
                    openModal={() => {
                      setClientModalData(client, DataActionType.Warning, true)
                    }}
                  />
                  <Actions
                    style={clientBoxItemStyles.textStyle}
                    onDelete={() => {
                      setClientModalData(client, DataActionType.Delete, true)
                    }}
                    onEdit={() => {
                      handleOnUpdateClicked(client)
                    }}
                    onDocumentsClicked={() => {
                      navigate(Screens.CLIENT_DOCUMENTS_SCREEN, {
                        id: encryptedData(client.uid),
                      })
                    }}
                    duration={duration}
                    onStatusRefreshClicked={() => onStatusRefreshClicked(client)}
                    isProfileCompleted={client.profile_completed}
                  />
                </TableRow>
              )}
            />
            {clientListMsg && clientListMsg !== Strings.LOADING_STARTED ? (
              <ErrorText
                txtStyle={{ marginVertical: 10 }}
                error={clientListMsg}
              />
            ) : null}
            <UploadClientDocModal
              visible={addModalVisible}
              onDismiss={hideAddClientModal}
              isCreateClient={true}
              client_Id={client?.uid}
              style={{ height: 500, width: width > 700 ? 600 : "90%" }}
              clientEmail={client?.email}
            />
            <DeleteModal
              visible={deleteModalVisible}
              onDismiss={() => {
                setClientModalData(null, DataActionType.Delete, false);
              }}
              onDelete={handleOnDeleteClicked}
              client={client}
              error={modalMsg}
            />
            <FilterModal
              visible={filterModalVisible}
              onDismiss={() => setFilterModalVisible(false)}
              setFilter={setFilter}
              filter={filter ? filter : []}
              statusList={statusList}
            />
            <WarningsModal
              visible={warningsModalVisible}
              onDismiss={() => setClientModalData(null, DataActionType.Warning, false)}
              tags={client?.non_immigrant_status ? client.non_immigrant_status?.tags : undefined}
            />
          </ScrollView>
        )}
      </BgImageContainer>
    </ProgressBar>
  );
};

export default React.memo(ClientScreen);

{/* <CustomTable
            actions={[{
              name: "filter",
              action: () => setFilterModalVisible(true),
              hoverText: "Filter Clients",
              iconName: "filter"
            }, {
              name: "download",
              action: () => {
                const data = exportToCsv(clientList);
                downloadFile({
                  data,
                  fileName: "client.csv",
                  fileType: "text/csv",
                });
              },
              hoverText: "Download CSV",
              iconName: "export"
            }, {
              name: "add",
              action: () => setAddModalVisible(true),
              hoverText: "Add Client",
              iconName: "adduser"
            }
            ]}
            tableTitle="Clients"
            inputData={() => {
              var clientsMap = []
              filteredClientList.map((client) => {
                const clMap = {}
                let nme = client.first_name + " " + client.last_name
                if (nme === " ") {
                  nme = client.email
                }
                clMap[Strings.NAME] = nme
                clMap[Strings.ConfirmInfo.EMPLOYER] = client.employer_name
                clMap[Strings.DAYS_SPENT] = client.non_immigrant_status.total_cap_out_days_in_country
                clMap[Strings.DAYS_REMAINING] = client.non_immigrant_status.cap_out_days_remaining
                clMap[Strings.VISA_TYPE_NAME] = client.non_immigrant_status.visa_type
                clMap[Strings.STATUS] = client.non_immigrant_status.status
                const rowActions = (client) => [{
                  action: () => {
                    setClientModalData(client, DataActionType.Delete, true)
                  },
                  hoverText: "Delete Client",
                  iconName: "trash-alt",
                  name: "delete"
                },
                {
                  action:()=>handleOnUpdateClicked(client),
                  hoverText: "Edit Client",
                  iconName: "edit",
                  name: "edit"
                },
                {
                  action: () =>
                    navigate(Screens.CLIENT_DOCUMENTS_SCREEN, {
                      id: encryptedData(client.uid),
                  }),
                  hoverText: "Client Documents",
                  iconName:"file-alt",
                  name:"documents",
                }, 
              {
               
              }]
                clMap[Strings.ACTION]= rowActions(client)
                clientsMap.push(clMap)

              })
              return clientsMap
            }}>

          </CustomTable> */}